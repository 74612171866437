import { Component } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils } from '@smooved/core';
import { NotificationSandbox, SvgIllustration, UiContext, UiDirection } from '@smooved/ui';
import { leadPassingSuccessBaseKey } from './real-estate-agent-start.constants';
import { EnergyStopSuppliersStatus } from '@app/services/energy-stop/enums/energy-stop-suppliers-status';

@Component({
    selector: 'app-real-estate-agent-start-container',
    templateUrl: './real-estate-agent-start.container.html',
})
export class RealEstateAgentStartContainer {
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly uiDirection = UiDirection;
    public readonly uiContext = UiContext;
    public readonly illustrations = SvgIllustration;

    public viewTransaction: () => void;
    public showSuccess: boolean;
    public move: Move;

    constructor(
        private readonly translateService: TranslateService,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly notificationSandbox: NotificationSandbox
    ) {}

    public onSuccess(move: Move): void {
        this.notificationSandbox.success(`${leadPassingSuccessBaseKey}.LABEL`, {
            plural: this.translateService.instant(`${leadPassingSuccessBaseKey}.${move.emailLeaver ? 'PLURAL' : 'SINGULAR'}`),
        });

        this.move = move;
        this.showSuccess = true;

        this.viewTransaction = (): void => void this.navigationSandbox.goToRealEstateDashboard(DbUtils.getStringId(move));
    }

    protected readonly EnergyStopSuppliersStatus = EnergyStopSuppliersStatus;
}
